

.expensetable td.Absent a {
    background: #FFF2C6;
    border-radius: 23.9338px;
    display: inline;
    color: #C58F03;
    font-size: 15px;
    padding: 5px 15px;
}
.expensepopop .modal-body{
    padding-left: 40px;
    padding-right: 40px;
}
.expensepopop .modal-body .form-control-plaintext {
    color: #96503F;
    font-weight: 600;
}
.expensepopop .send{
    width: 100%;
    background-color: #96503F;
    border-color: #96503F;
}
.expensepopop .send:hover{
    background-color: unset;
    border-color: #96503F;
    color: #000;
}