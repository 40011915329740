/* .table-action{
    position: relative;
}
.attend-table .dropmenu{
    background-color: #fff;
    padding: 15px 10px 0;
    position: absolute;
    left: -14px;
    top: 24px;
    width: 150px;
    border-radius: 5px;
    box-shadow: 0px 2.92913px 7.32283px rgba(0, 0, 0, 0.1);
} */
.table-action button {
    background: #fff !important;
    color: rgba(0, 0, 0, 0.87) !important;
    border: unset !important;
}
.table-action button svg {
    font-size: 17px;
}
.table-action .dropdown-toggle::after {
    display: none;
}
.table-action .dropdown-menu.show{
    padding: 0;
    border: unset;
    box-shadow: 0px 2.92913px 7.32283px rgb(0 0 0 / 10%);
    border-radius: var(--br);
}

.table-action .dropdown-menu.show a {
    color: #000;
    padding: 9px 10px;
    font-size: 12px;
    border-radius: var(--br);
}
.table-action .dropdown-menu.show a:hover{
    text-decoration: unset;
    background-color: #96503F;
    color: #fff;
    border-radius: var(--br);
    
}
.table-action .dropdown-menu.show a:last-child:hover{
}
.breakpopop .modal-content{
    padding: 10px;
}
.breakpopop .modal-header {
    border: unset;
}
.breakpopop .modal-header h4 {
    font-size: 20px;
    color: #96503F;
}
.breakpopop .modal-body .form-label {
    font-size: 15px;
    font-weight: 600;
}
.breakpopop .modal-body .form-control-plaintext {
    color: #7A7979;
    font-size: 15px;
}
.breakpopop .modal-body .form-control:focus{
    box-shadow: unset;
}
.breakpopop .modal-body select:required:invalid {
    color: #939393;
  }
  .breakpopop .modal-body option[value=""][disabled] {
    display: none;
  }
  .breakpopop .modal-body option {
    color: black;
  }
.breakpopop .modal-body .form-select:focus{
    box-shadow: unset;
}
.breakpopop .modal-footer{
    border: unset;
    justify-content: space-between;
}
.breakpopop .modal-footer button.cancel {
    color: #96503F;
    background: #fff;
    border-color: #B5B5B5;
    width: 35%;
    font-size: 15px; 
       padding: 10px;
}
.breakpopop .modal-footer button.send {
    color: #fff;
    background: #96503F;
    border-color: #96503F;
    width: 60%;
    padding: 10px;
    font-size: 15px;
}
.breakpopop .modal-footer button:hover{
    background-color: unset;
    color: #000;
}


/* responsive code */
@media only screen and (max-width : 768px) {
    .breakpopop .modal-content {
        padding: 10px 0;
    }
}
