


.notification {
    position: relative;
}
.notification .noti-btn svg {
    font-size: 22px;
    cursor: pointer;
}
.notification .dropmenu{
    background-color: #fff;
    position: absolute;
    right: -120px;
    top: 24px;
    width: 350px;
    border-radius: 5px;
    box-shadow: 0px 2.92913px 7.32283px rgba(0, 0, 0, 0.1);
    z-index: 1;
}
.notification .dropmenu ul {
    padding: 0;
}
.notification .dropmenu ul li{
    list-style: none;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    gap: 20px;  
    border-radius: 5px 5px 0px 0px;
    align-items: center;
}
.notification .dropmenu ul li span {
    width: 65px;
    height: 34px;
    background: #FFD2C7;
    border-radius: 5px;
    text-align: center;
    line-height: 28px;
    display: inline-block;
    color: #96503F;
}
.notification .dropmenu ul li p{
    font-size: 15px;
}
.notification .dropmenu ul li a{
    text-decoration: unset;
    color: #96503F;
    display: block;
    font-size: 15px;
}
.notification .dropmenu ul li:hover{
    background-color: #96503F;
    color: #fff;
}
.notification .dropmenu ul li:hover a{
    color: #fff;
}