.sidebar-page .navbar-logo {
    margin-bottom: 40px;
    margin-top: 20px;
}

.sidebar-page .navbar-logo a.logo img {
    margin: 0 auto;
    display: block;
    width: 170px;
}

.sidebar-page .nav-menu {
    background-color: #96503F;
    width: 250px;
    height: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 9;
}

.sidebar-page .nav-menu.active {
    left: 0;
    transition: 350ms;
}

.sidebar-page .nav-text {
    display: flex;
    /* justify-content: start; */
    align-items: center;
    padding: 8px 0px 8px 10px;
    list-style: none;
    height: 55px;
}

.sidebar-page .nav-text a {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    font-weight: 500;
    gap: 8px;
}

.sidebar-page .nav-text a.active {
    background-color: #C58779;
    color: #fff !important;
}

.sidebar-page .nav-text a svg {
    font-size: 17px;
}

.sidebar-page .nav-text a:hover {
    background-color: #C58779;
    color: #fff !important;
}

.sidebar-page .nav-text a:hover svg {
    color: #fff !important;
}

.sidebar-page .nav-menu-items {
    width: 100%;
    padding-left: 0px;
}

.sidebar-page .navbar-toggle {
    background-color: #96503F;
    width: 100%;
    height: 80px;
    align-items: center;
}


.sidebar-page .navbar.formob {
    display: none;
}




/* mobile responsive code */
@media (max-width:767px) {
    .sidebar-page .menu-bars {
        margin-left: 1rem;
        font-size: 2rem;
        background: none;
    }

    .sidebar-page .menu-bars svg {
        font-size: 25px;
    }

    .sidebar-page .navbar.formob {
        display: flex !important;
    }

    .sidebar-page .navbar.formob a img {
        width: 150px;
    }

    nav.navbar-menu.forpc {
        display: none;
    }

    nav.nav-menu.formob {
        box-shadow: -2px 7px 18px 0 rgb(0 0 0 / 22%);
        z-index: 9;
    }

    .sidebar-page a.logo {
        margin-right: 1rem;
    }

    nav.nav-menu.formob .navbar-toggle {
        background-color: #96503F;
        /* width: 90%;
        height: 80px;
        display: inline-flex;
        justify-content: right;
        align-items: center; */
    }

    nav.nav-menu.formob .navbar-toggle a svg {
        font-size: 25px;
    }

    .sidebar-page .nav-text a.active svg {
        color: #96503F !important;
    }

}



/* Ipad responsive code */
@media screen and (max-width: 1050px)  {
    .sidebar-page .navbar.formob {
        display: block;
    }
    .sidebar-page .menu-bars {
        margin-left: 1rem;
        font-size: 2rem;
        background: none;
    }

    .sidebar-page .menu-bars svg {
        font-size: 25px;
    }

    .sidebar-page .navbar.formob {
        display: flex !important;
    }

    .sidebar-page .navbar.formob a img {
        width: 150px;
    }

    nav.navbar-menu.forpc {
        display: none;
    }

    nav.nav-menu.formob {
        box-shadow: -2px 7px 18px 0 rgb(0 0 0 / 22%);
        z-index: 9;
    }

    .sidebar-page a.logo {
        margin-right: 1rem;
    }

    nav.nav-menu.formob .navbar-toggle {
        background-color: #96503F;
        /* width: 90%;
        height: 80px;
        display: inline-flex;
        justify-content: right;
        align-items: center; */
    }

    nav.nav-menu.formob .navbar-toggle a svg {
        font-size: 25px;
    }

    .sidebar-page .nav-text a.active svg {
        color: #96503F !important;
    }

}