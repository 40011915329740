.table{
    width: 100%;
}
.header{
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    background: #FCF6AB;
    border-radius: var(--br);
    color: var(--bg);
    font-weight: 500;
}
.body_div {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    background: white;
    margin: 10px 0;
    border: 1px solid #E0E0E0;
}
.body_div > span:first-child {
    color: black;
}