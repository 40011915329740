.inner_container {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  row-gap: 15px;
  justify-content: space-between;
}
.invisible {
  display: none;
}
.add_expense_textarea {
  min-width: 100%;
  border: 1px solid #dcd8d8;
  border-radius: var(--br);
  padding: 20px;
  width: 100%;
  max-width: 100%;
  min-height: 100px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  outline: none;
}

.add_expense_seleecct_container {
  width: 49%;
  /* padding-right: 30px; */
}
.add_expense_seleecct_container label,
.textarea_container label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #96503f;
  display: block;
}
.add_expense_seleecct_container > div,
.add_expense_seleecct_container > div select {
  width: 100% !important;
}


.textarea_container{}
.textarea_container label{}
.textarea_container textarea{
  color: #939393;
  font-weight: 400;
}