.table_container {
  display: flex;
  justify-content: space-between;
}
.table_container_child {
  width: 48%;
}
.table_heading {
  font-weight: 600;
  color: var(--bg);
  margin: 20px 0;
  font-size: 1.1em;
}

.header{
  height: 45px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  background: #FCF6AB;
  border-radius: var(--br);
  color: var(--bg);
  font-weight: 500;
}

.grade_btn{width: 190px;
  height: 45px;
  border-radius: 4px;
  font-weight: 500;
  color: white;
  border: none;
  background: #96503f;
  float: right;
  margin: 30px 0;}