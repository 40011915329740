@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

:root {
  --bg: #96503f;
  --br: 4px;
}

.active{
  background-color: #c58779;
}

input{
  font-size: 14px;
}

input::placeholder{
  font-size: 14px;
}

input:focus,
select:focus,
textarea:focus{
outline: none !important;
box-shadow: none !important;
}

.form-control:focus{
  border-color: #dcd8d8 !important;
}