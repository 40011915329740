


.behivour-detail {
    padding: 30px 0 20px;
    border-bottom: 1px solid #B5B5B5;
}
.behivour-detail  h5{
    font-size: 17px;
    font-weight: 600;
}
.behivour-detail  p{
    color: #777777;
    font-size: 14px;
    padding-bottom: 10px;
}

.behivour-detail ul.top-behv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
}

.behivour-detail ul.top-behv li {
    list-style: none;
    background: #D9D9D9;
    width: 100%;
    border-right: 10px solid #fff;
    height: 20px;
}

.behivour-detail ul.top-behv li:first-child {
    background-color: #96503F;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: relative;
}
.behivour-detail ul.top-behv li:first-child:after{
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    background: #96503F;
    border-radius: 50%;
    left: 95%;
    border: 5px solid #FCF6AB;
    top: -10%;
}
.behivour-detail ul.top-behv li:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.behivour-detail ul.bottom-behv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
}
.behivour-detail ul.bottom-behv li{
    list-style: none;
    background: #fff;
    width: 100%;
    font-size: 15px;
    font-weight: 600;
}