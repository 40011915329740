




.home-page {
    /* overflow-x: hidden; */
    /* background: #96503F; */
}
.home-page .content-side {
    background: #fff;
    /* padding: 30px 40px; */
    padding: 20px;
}

.attend-table thead {
    font-size: 17px;
    font-weight: 600;
    background: #FCF6AB;
}
.attend-table thead tr th {
    color: var(--bg);
    font-weight: 500;
    font-family: inherit;
}
.attend-table table thead th {
    border-top: 1px solid #E0E0E0;
    text-align: center;
    border-bottom: 1px solid #E0E0E0;
    font-size: 1rem;
}
.attend-table tbody {
    font-size: 15px;
    font-weight: 600;
    background-color: #fff;
    padding: 10px;
}
.attend-table tbody tr td {
    border-bottom: 1px solid #E0E0E0;
    border-top: 1px solid #E0E0E0;
    font-family: inherit;
    font-size: 15px;
    color: #464646;
    font-weight: 500;
}
.attend-table tbody .empname {
    align-items: center;
    gap: 10px;
}
.attend-table tbody .empname img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.attend-table tbody .empname p {
    color: #96503F;
    color: #96503F;
    text-decoration: underline;
    margin-bottom: 0;
    font-weight: 500;
}
.attend-table tbody a.Present {
    background: #C7FFC6;
    border-radius: 23.9338px;
    display: inline-block;
    color: #07C503;
    font-size: 15px;
    padding: 5px 15px;
    text-decoration: unset;
}
.attend-table tbody a.Absent {
    background: #FFC6C6;
    border-radius: 23.9338px;
    display: inline-block;
    color: #C50303;
    font-size: 15px;
    padding: 5px 15px;
    text-decoration: unset;
}
.attend-table tbody a.Pending{
    background: #FFF2C6;
    border-radius: 23.9338px;
    display: inline-block;
    color: #C58F03;
    font-size: 15px;
    padding: 5px 15px;
    text-decoration: unset;
}
.attend-table tbody td a {
    color: #9A9A9A;
    text-decoration: unset;

}
.attend-table tbody td a:hover{
    color: #96503F;
    text-decoration: underline;
}






@media  (max-width:767px) {

    .home-page .content-side {
        padding: 20px 15px;
        margin: 0;
    }
    .general{
        padding: 20px 10px !important;
    }
}