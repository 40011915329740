.circle_container{
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background: #fcf6ab;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: var(--bg);
}

.circle_container h1{
    font-size: 60px;
    font-weight: 500;
    margin-bottom: 0;

}
.circle_container p{
    font-size: 17px;
    margin-bottom: 0;
}