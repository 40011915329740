

.rangeslide .irs--big .irs-bar {
    background: #96503F;
    border: 1px solid #96503F;
    box-shadow: unset;
}
.rangeslide .irs--big .irs-handle {
    background: #96503f !important;
    border: 5px solid #FCF6AB;
    box-shadow: unset;
}
.rangeslide .irs--big .irs-handle:focus-visible{
    outline: unset;
}
.example-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: #ffffff;
    border: 5px solid #3774ff;
    border-radius: 100%;
    display: block;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
  }
  .example-thumb.active {
    background-color: grey;
  }
  .example-track {
    position: relative;
    background: #ddd;
  }
  .example-track.example-track-0 {
    background: #83a9ff;
  }