
.filters {
    padding: 30px 0;
}
.filters form .col {
    position: relative;
}
.filters form .col svg {
    position: absolute;
    color: #96503F;
    font-size: 18px;
    top: 44px;
    right: 5%;
}
.filters input {
    /* padding: 10px;
    color: #939393;
    font-size: 15px;
    border: 1px solid #B5B5B5;
    border-radius: 0.375rem; */
    
    width: 100%;
    height: 47px;
    border-radius: var(--br);
    border: 1px solid #dcd8d8;
    padding: 0 10px;
    margin-top: 0px;
    appearance: none;
    color: #939393;
    font-weight: 400;
}
.filters input:focus{
    outline: unset;
}
.filters .form-control {
    padding: 10px;
    width:500px
}

.filters .form-control:focus{
    box-shadow: unset;
}
.filters .form-control::placeholder{
    font-size: 15px;
    color: #939393;
}
.filters .form-label {
    font-size: 14px;
    font-weight: 500;
    color: var(--bg);
    margin-bottom: 5px;
}

.filters select{
    color: #939393;
    border: 1px solid #B5B5B5;
    background-color: #fff;
    /* filter: invert(1); */
    font-size: 15px;
    padding: 10px;
}
.filters select option {
    background: #fff;
    color: #000;
}

.filters select:focus{
    box-shadow: unset;
}

.filters select:required:invalid {
    color: #939393;
  }
  .filters option[value=""][disabled] {
    display: none;
  }
  .filters option {
    color: black;
  }






/* responsive code */
@media only screen and (max-width : 768px) {
    .filters form .mb {
        display: block;
    }
    .filters form .col{
        padding-bottom: 20px;
    }
    /* .filters form .col svg {
        right: 3%;
    } */
  
}



/* Ipad responsive code */
@media screen and (max-width: 1050px)  {
     .filters form .col svg {
        right: 3%;
     }
}
.visible {
    display: block !important;
  }
  
  .search_table {
    width: 620px;
    height: 250px;
    background: white;
    display: none;
    position: ABSOLUTE;
    z-index: 1;
    top: 100px;
    overflow-y: scroll;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px;
    border-radius: var(--br);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

