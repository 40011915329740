


.emp-detail {
    border-top: 1px solid #B5B5B5;
    border-bottom: 1px solid #B5B5B5;
    padding: 30px 0 20px;
}
.emp-detail h3 {
    font-size: 20px;
    color: #96503F;
    padding-bottom: 10px;
}
.time-approval .emp-detail .form-label {
    font-size: 14px;
    color: #000;
    font-weight: 600;
}
.time-approval .emp-detail .form-control-plaintext {
    color: #777777;
    font-size: 15px;
}
.time-approval .time-filtr {
    display: flex;
    gap: 30px;
    width: 100%;
    padding: 30px 0; 
}
.time-approval .time-filtr input {
    padding: 10px;
    width: 100%;
    color: #939393;
    font-size: 15px;
    border: 1px solid #B5B5B5;
    border-radius: 0.375rem;
}
.time-approval .time-filtr input:focus{
    outline: unset;
}
.time-approval .time-filtr .col{
    position: relative;
}
.time-approval .time-filtr svg {
    position: absolute;
    color: #96503F;
    font-size: 18px;
    top: 44px;
    right: 5%;
}
.time-approval .time-filtr .form-select{
    padding: 9px;
    color:  #939393;
    font-size: 15px;
}
.time-approval .time-filtr .form-select:focus{
    box-shadow: unset;
}
.time-approval .time-filtr select option{
    color: #000;
}
.time-approval .form-label {
    color: #96503F;
    font-weight: 600;
}
.time-approval form .image-upload {
    width: 100%;
    border: 1px solid #ced4da;
    padding: 10px 0;
    border-radius: 0.375rem;
    position: relative;
    z-index: 9;
}
.time-approval form .image-upload:after {
    content: "Choose File";
    position: absolute;
    background: #B5B5B5;
    width: 96px;
    height: 61px;
    left: 0px;
    top: -5px;
    z-index: 1;
    text-align: center;
    line-height: 64px;
}
.time-approval form .buttons {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
}
.time-approval form .buttons .Cancel {
    background: #fff;
    border: 1px solid #CBCBCB;
    font-size: 15px;
    padding: 2px 20px;
    border-radius: var(--br);
    color: #96503F;
    height: 40px;
    font-weight: 500;
}
.time-approval form .buttons .send {
    background: #96503F;
    border: 1px solid #96503F;
    font-size: 15px;
    color: #fff;
    font-weight: 500;

    font-size: 15px;
    padding: 2px 20px;
    border-radius: var(--br);
    height: 40px;
}


/* responsive code */
@media only screen and (max-width : 768px) {
    .time-approval .time-filtr{
        width: 100%;
        display: inline-block;
    }
    .time-approval .time-filtr .col {
        padding-bottom: 20px;
    }
    .time-approval form .image-upload:after {
        width: 105px;
        font-size: 14px;
        color: #000;
    }
    .time-approval form .buttons .send {
        padding: 15px 20px;
    }
}
