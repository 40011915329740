.table {
  width: 100%;
  table-layout: fixed;
  /* margin-top: 30px; */
  border-spacing: 0 10px;
}

.table thead {
  background: #fcf6ab;
  color: #96503f;
  z-index: 10;
}
.table thead tr {
  height: 50px;
  border-radius: var(--br);
  text-align: left;
  outline: 1px solid #fcf6ab;
}
.table thead tr th {
  padding-left: 20px;
  font-weight: 500;
  font-size: initial;
}
.table tbody tr {
  height: 50px;
  border-radius: var(--br);
  outline: 1px solid #dcd8d8;
}
.table tbody tr td {
  padding-left: 20px;
  font-size: 15px;
  color: #464646;
  font-weight: 500;
}

.table button {
  height: 30px;
  width: 85px;
  margin: auto;
  display: block;
  background: #f17d03;
  color: white;
  border: none;
  border-radius: var(--br);
  cursor: pointer;
  font-weight: 500;
}

.name_feild {
  color: #96503f !important;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
}



.name_feild img{
  margin-right: 10px;
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.table_input{
  height: 50px;
  border-radius: var(--br);
  outline: 1px solid #dcd8d8;
  border: none;
  font-size: 29px;
  color: #a09d9d;
  padding: 0 25px;
  width: max-content;
  max-width: 150px;
  margin-top: 30px;
}

.none{
  display: none;
}

@media screen and (max-width:700px) {
  .table{
    width: 1000px;
  }
  .table_container{
    width: 100%;
    overflow-y: scroll;
  }
}