.input_div {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 31%;
  margin-right: 30px;
}

.wd50 {
  width: 49% !important;
  margin-bottom: 20px;
  margin-right: 0;
}

.input_div :last-child {
  margin-right: 0 !important;
}
.input_div label {
  font-size: 14px;
  font-weight: 500;
  color: #96503f;
}
.input_div input,
.input_div select {
  height: 47px;
  border-radius: var(--br);
  border: 1px solid #dcd8d8;
  padding: 0 10px;
  margin-top: 10px;
  appearance: none;
  color: #939393;
  font-weight: 400;
}

.input_div input:focus-visible,
.input_div select:focus-visible {
  outline: 1px solid var(--bg);
}

.input_div img.img2 {
  right: 12px;
  top: 45px;
  position: absolute;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .input_div {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .wd50{
    width: 100% !important;
    margin-right: 0;
  }
}
