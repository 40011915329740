.container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 50px 0;
}
.meter_container > h3 {
  text-align: center;
  font-weight: 500;
  margin-top: 10px;
  color: var(--bg);
  font-size: 1.1em;
}
