


.login-page {
    width: 30%;
    margin: 150px auto;
}
.login-page img {
    width: 100px;
    margin: 30px auto;
    display: block;
}
.login-page .login-form {
    background: #FFFFFF;
    border: 1px solid #B5B5B5;
    border-radius: 10px;
    padding: 30px 20px;
}
.login-page .login-form .form-label {
    font-size: 15px;
    font-weight: 600;
}
.login-page .login-form .form-control {
    background: #F5F5F5;
    padding: 10px;
}
.login-page .login-form .form-control:focus{
    box-shadow: 0 0 0 0.1rem rgb(150 80 63);
}
.login-page .login-form .password {
    position: relative;
}
.login-page .login-form  .password svg.pw-icon {
    position: absolute;
    top: 49px;
    right: 3%;
    color: #000;
}
.login-page .login-form button {
    background: #96503F;
    border-radius: 5px;
    border: 1px solid #96503F;
    width: 100%;
    padding: 10px;
    font-size: 15px;
}
.login-page .login-form button:hover{
background-color: #f17d03;
border: 1px solid #f17d03;
}
