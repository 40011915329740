.topmenu {
    background: #EBEBEB;
    padding: 10px;
}

.topmenu .flex-box {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.topmenu .flex-box form {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 8px;
    padding-right: 10px;
    width: 30%;
}

.topmenu .flex-box form .form-control {
    border: unset;
}

.topmenu .flex-box form svg {
    color: #929395;
    font-size: 20px;
}

.topmenu .flex-box .noti {
    display: flex;
    align-items: center;
    gap: 30px;
}

.topmenu .flex-box .noti p {
    font-size: 15px;
}

.topmenu .flex-box p {
    margin: 0;
}

.topmenu .flex-box .noti p svg {
    font-size: 20px;
}




/* responsive code */
@media only screen and (max-width : 768px) {
    .topmenu {
        display: none;
    }
}