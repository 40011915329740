.floor-store {
    display: flex;
    gap: 30px;
    padding-top: 40px;
}

.floor-store .dropdown button {
    background: #fff;
    color: #96503F;
    font-size: 17px;
    border-color: #B5B5B5;
    padding: 10px 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 15px;
}
.floor-store .dropdown button:hover{
    background-color: unset;
    color: #96503F;
}
.floor-store .dropdown button svg {
    font-size: 20px;
}