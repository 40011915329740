

.widget {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 0;
}
.wid-card .link{
    text-decoration: none;
    color: black;
}
.widget .wid-card {
    /* background: #FFFFFF;
    border: 1px solid #A0A0A0;
    padding: 15px 20px; */
    border-radius: var(--br);
    border: 1px solid #dcd8d8;
    padding: 20px;
    /* flex: 1; */
}
 .wid-card p{
    font-weight: 600;
}
.wid-card h1 {
    font-size: 2em;
    font-weight: 600;
}
.wid-card .green {
    background: #C7FFC6;
    border-radius: 23.9338px;
    display: inline-block;
    color: #07C503;
    font-size: 13px;
    padding: 4px 10px;
}
 .wid-card .red {
    background: #FFD2C7;
    border-radius: 23.9338px;
    display: inline-block;
    color: #C50303;
    font-size: 13px;
    padding: 4px 10px;
}


/* phone responsive code */
@media only screen and (max-width : 768px) {
    .widget {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 0;
    }
    .widget .wid-card {
        margin-bottom: 20px;
        padding: 15px;
    }
    .widget .wid-card span.green {
        font-size: 11px;
        padding: 5px 7px;
    }
    .widget .wid-card span.red{
        font-size: 11px;
        padding: 5px 7px;
    }
}


/* Ipad responsive code */
@media screen and (max-width: 1050px)  {
    .widget .wid-card p {
        height: 50px;
    }
    /* .widget {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 23px;
    } */

}
