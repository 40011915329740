

.btn_container {
    display: flex;
    justify-content: end;
    margin-top: 20px;
  }
  .btn_container button {
    width: max-content;
    padding: 0 20px;
    height: 40px;
    margin-left: 10px;
    border-radius: var(--br);
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
  }
  .cancel {
    color: #96503f;
    border: 1px solid #b5b5b5;
    background: white;
  }
  .accept {
    background: #96503f;
    color: white;
    border: 1px solid #96503f;
  }