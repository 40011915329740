.container {
  padding: 20px 0;
  border-bottom: 1px solid #b5b5b5;
}
.container h3 {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 17px;
  color: #333;
}
.container p {
  font-size: 14px;
  color: #959292;
}
